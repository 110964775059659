import React, { useMemo, useEffect, useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Ticker from 'react-ticker';
import { graphql, useStaticQuery } from 'gatsby';
import SectionContainer from '../shared/SectionContainer';
import TranslatedText from '../shared/TranslatedText';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.lightBlue,

    '& .carousel-initialized': {
      overflow: 'visible',
    },
  },
  imageContainer: {
    height: 150,
    width: 150,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  ticker: {
    height: 150,
    width: 150,
    padding: 24,
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  tickerImg: {
    width: 100,
    maxWidth: '90%',
    maxHeight: '90%',
  },
  tickerPlaceholder: {
    height: 150,
  },
}));

const compare = ({ node: { name } }, { node: { name: nextName } }) => {
  if (Number(name) < Number(nextName)) return -1;
  if (Number(name) > Number(nextName)) return 1;
  return 0;
};

const MainTechToolbox = () => {
  const {
    stack: { edges: items },
  } = useStaticQuery(graphql`
     query {
      stack: allFile(filter: {relativePath: {regex: "/main/techToolbox/"}}) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);
  const classes = useStyles();
  const images = useMemo(() => items.sort(compare).map(({ node: { publicURL } }) => publicURL), [items]);
  const [imageList, setImageList] = useState(images);


  useEffect(() => {
    setTimeout(() => setImageList(images), 1000);
  }, [images]);

  return (
    <Box className={classes.container}>
      <SectionContainer
        upMdPaddingX={5}
      >
        <Box my={3}>
          <Typography
            variant="h3"
            className={classes.header}
          >
            <TranslatedText id="main.techToolbox.header" />
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography
            variant="h4"
            className={classes.header}
          >
            <TranslatedText id="main.techToolbox.subheader" />
          </Typography>
        </Box>
      </SectionContainer>
      <Box pb={4}>
        {imageList && !!imageList.length ? (

          <Ticker
            speed={10}
            move
          >
            {() => (
              <Box display="flex">
                {images.map((item) => (
                  <Box
                    p={2}
                    key={item}
                    className={classes.ticker}
                  >
                    <img
                      alt="tool"
                      src={item}
                      className={classes.tickerImg}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Ticker>
        ) : <Box className={classes.tickerPlaceholder} />}
      </Box>
    </Box>
  );
};

export default MainTechToolbox;
