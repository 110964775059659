import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SectionContainer from '../shared/SectionContainer';
import TranslatedText from '../shared/TranslatedText';
import { LocalizedAnchorLink } from '../shared/LocalizedLink';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 1200,
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  itemContainer: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing(4),
    boxSizing: 'border-box',
  },
  imageContainer: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    minHeight: 170,
  },
  imageBackground: {
    position: 'absolute',
    backgroundColor: '#E8EDF6',
    height: 100,
    width: 100,
    borderRadius: 20,
    transform: 'rotate(-45deg)',
  },
  image: {
    zIndex: 1,
  },
  labelContainer: {
    flex: 1,
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '22px',
    textAlign: 'center',
  },
  descriptionContainer: {
    height: 120,
    maxWidth: 400,
    padding: theme.spacing(2, 0),
    boxSizing: 'border-box',
    '& > span': {
      color: theme.palette.text.hint,
    },
  },
  linkLabelContainer: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    '& > a ': {
      textDecoration: 'none',
      '& > span': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        '& > svg': {
          fontSize: theme.spacing(3),
          padding: theme.spacing(0, 0.5),
          boxSizing: 'border-box',
        },
      },
    },
  },
}));

const Item = ({
  classes, imageUrl, label, description, linkLabel, link,
}) => (
  <Box className={classes.itemContainer}>
    <Box className={classes.imageContainer}>
      <Box className={classes.imageBackground} />
      <Box className={classes.image}>
        <img src={imageUrl} alt={<TranslatedText id={label} />} />
      </Box>
    </Box>
    <Box className={classes.labelContainer}>
      <TranslatedText id={label} />
    </Box>
    <Box className={classes.descriptionContainer}>
      <Typography component="span">
        <TranslatedText id={description} />
      </Typography>
    </Box>
    <Box className={classes.linkLabelContainer}>
      <LocalizedAnchorLink to={link} className={classes.buttonLink} isAnchorLink>
        <Typography component="span" color="secondary">
          <TranslatedText id={linkLabel} />
          <ArrowForwardIosIcon />
        </Typography>
      </LocalizedAnchorLink>
    </Box>
  </Box>
);

Item.propTypes = {
  classes: PropTypes.shape({
    itemContainer: PropTypes.string,
    imageContainer: PropTypes.string,
    imageBackground: PropTypes.string,
    labelContainer: PropTypes.string,
    image: PropTypes.string,
    descriptionContainer: PropTypes.string,
    linkLabelContainer: PropTypes.string,
    buttonLink: PropTypes.string,
  }).isRequired,
  imageUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

const MainFindOutMore = () => {
  const { howWeWork, internships, benefits } = useStaticQuery(graphql`
    {
      howWeWork: file(relativePath: {regex: "/findOutMore/"}, name: {eq: "discussion"}) {
        publicURL
      }
      internships: file(relativePath: {regex: "/findOutMore/"}, name: {eq: "idea-bulb"}) {
        publicURL
      }
      benefits: file(relativePath: {regex: "/findOutMore/"}, name: {eq: "ico_edu4you"}) {
        publicURL
      }
    }
  `);

  const classes = useStyles();

  return (
    <SectionContainer
      header={<TranslatedText id="main.findOutMore.header" />}
      upMdPaddingX={5}
    >
      <Box display="flex" className={classes.container}>
        <Item
          classes={classes}
          label="main.findOutMore.howWeWork.label"
          description="main.findOutMore.howWeWork.description"
          imageUrl={howWeWork.publicURL}
          linkLabel="main.findOutMore.howWeWork.linkLabel"
          link="/careers#howWeWork"
        />
        <Item
          classes={classes}
          label="main.findOutMore.benefits.label"
          description="main.findOutMore.benefits.description"
          imageUrl={benefits.publicURL}
          linkLabel="main.findOutMore.benefits.linkLabel"
          link="/careers#benefits"
        />
        <Item
          classes={classes}
          label="main.findOutMore.internships.label"
          description="main.findOutMore.internships.description"
          imageUrl={internships.publicURL}
          linkLabel="main.findOutMore.internships.linkLabel"
          link="/internships"
        />
      </Box>
    </SectionContainer>
  );
};

export default MainFindOutMore;
