const ITEMS_COUNT = 6;
const LABEL_PREFIX = 'main.whatWeDo';

export default () => (
  new Array(ITEMS_COUNT).fill(undefined).map((_, i) => ({
    image: `${i + 1}`,
    label: `${LABEL_PREFIX}.item${i + 1}.label`,
    description: `${LABEL_PREFIX}.item${i + 1}.description`,
  }))
);

export const ITEM_SIZE = 400;
export const ITEM_SIZE_SMALL = 320;
