import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
//import particleJS from 'particles.js';
import {isMobile} from "react-device-detect";
if (typeof window !== `undefined`) {
    require('particles.js')
}


const SMALL_SCREEN_HEIGHT = 'calc(100vh - 66px)';
const isBrowser = typeof window !== "undefined"
const useStyles = makeStyles((theme)=>({
    '@keyframes blurer':{
        from:{ 'filter':'blur(0.1px)' },
        to: {'filter':'blur(2px)' }

    },
    particles:{
        animationName: '$blurer',
        animationDuration: '5s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        height: 'calc(100vh - 120px)',
        minHeight: 650,
        width: '100%',
        minWidth: '100vw',

        position: 'absolute',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 120px)',
            minHeight: 600,
        },
        [theme.breakpoints.down('sm')]: {
            height: SMALL_SCREEN_HEIGHT,
            minHeight: 600,
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 365,
            maxHeight: 450,
        },
        ...(isMobile && {
            minHeight: 365,
            maxHeight: 450,
        }),
    }

}))


const Particles = () => {
    const classes= useStyles();
    useEffect(()=>{
        if(isBrowser){
            particlesJS("particles-js",
                {
                    "particles":
                        {
                            "number": {"value":40, "density": {"enable":true,"value_area":800}},
                            "color":{"value":"#ffffff"},
                            "shape":{"type":"circle","stroke":{"width":0,"color":"#ffffff"}},
                            "opacity":{"value":0.3,"random":false,"anim":{"enable":false,"speed":1,"opacity_min":0.1,"sync":false}},
                            "size":{"value":3,"random":true,"anim":{"enable":false,"speed":40,"size_min":0.1,"sync":false}},
                            "line_linked":{"enable":false,"distance":150,"color":"#ffffff","opacity":0.4,"width":1},
                            "move":{"enable":true,"speed":1,"direction":"none","random":false,"straight":false,"out_mode":"out","bounce":false,"attract":{"enable":false,"rotateX":600,"rotateY":1200}}
                        },
                    "interactivity":{"detect_on":"canvas","events":{"onhover":{"enable":false,"mode":"repulse"},"onclick":{"enable":false,"mode":"push"},"resize":true},"modes":{"grab":{"distance":400,"line_linked":{"opacity":1}},"bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},"repulse":{"distance":200,"duration":0.4},"push":{"particles_nb":4},"remove":{"particles_nb":2}}},"retina_detect":true});

        }

    },[]);

    return <div className={classes.particles} id='particles-js'></div>
}


export default Particles;
