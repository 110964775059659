import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import TrackVisibility from 'react-on-screen';
import {
  Box, Button, Typography, useMediaQuery, useTheme, makeStyles, Popover, Tooltip
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SectionContainer from '../shared/SectionContainer';
import TranslatedText from '../shared/TranslatedText';

const useStyles = makeStyles((theme) => ({
  sectionWrapper: {
    position: 'relative',
  },
  container: {
    maxWidth: 1200,
    padding: theme.spacing(10, 2),
  },
  background: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    overflow: 'hidden',
    zIndex: -1,
    '& > img': {
      height: '120%',
      marginLeft: '40%',
    },
  },
  descriptionContainerHeader: {
    fontSize: 32,
    fontWeight: 'bold',
    lineHeight: '44px',
  },
  descriptionContainerText: {
    display: 'inline-block',
    color: theme.palette.text.hint,
    padding: theme.spacing(1, 0),
  },
  descriptionContainerSeeMoreLess: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  invisible: {
    visibility: 'hidden',
  },
  button: {
    margin: `-${theme.spacing(3)}px`,
    '& > span > a': {
      textDecoration: 'none',
      color: theme.palette.common.white,
    },
  },
  imageContainer: {
    '& img': {
      maxWidth: '100%',
    },
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  tooltipAnchor: {
    cursor: 'pointer',
  },
  descriptionContainerTextTooltip: {
    fontSize: 10,
    maxWidth: '80vw',
    width: 300,
  },
}));

const MainOurCompany = () => {
  const { officeImg, logoImg } = useStaticQuery(graphql`
    {
      officeImg: file(relativePath: {regex: "/ourCompany/"}, name: {eq: "NOVOMATIC_hq"}) {
        publicURL
      }
      logoImg: file(name: {eq: "N_logo"}) {
        publicURL
      }
    }
  `);
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCollapsed, setIsCollapsed] = useState(true);
  const classes = useStyles();
  const tooltipRef = useRef();

  useEffect(() => {
    const onScroll = () => {
      setTooltipOpened(false);
    };
    document.addEventListener('mousewheel', onScroll);
    document.addEventListener('touchmove', onScroll);
    return () => {
      document.removeEventListener('mousewheel', onScroll);
      document.removeEventListener('touchmove', onScroll);
    };
  }, []);

  return (
    <Box className={classes.sectionWrapper}>
      <SectionContainer>
        <Box className={classes.background}>
          <img src={logoImg.publicURL} alt="Novomatic" />
        </Box>
        <TrackVisibility once throttleInterval={100} partialVisibility>
          {({ isVisible }) => (
            <Box
              className={cn(classes.container, classes.invisible, { 'ntp-block-animated': isVisible })}
              display="flex"
              alignItems={isSmallScreen ? 'center' : 'flex-start'}
              justifyContent="center"
              flexDirection={isSmallScreen ? 'column' : 'row'}
              width="100%"
              boxSizing="border-box"
              maxWidth="1200"
            >
              <Box
                flex={1}
                display="flex"
                justifyContent={isSmallScreen ? 'center' : 'flex-start'}
                alignItems="flex-start"
                flexDirection="column"
                className={classes.descriptionContainer}
                textAlign={isSmallScreen ? 'center' : 'left'}
                pb={3}
              >
                <Box textAlign={isSmallScreen ? 'center' : 'left'}>
                  <Typography
                    component="span"
                    variant="h4"
                    className={classes.descriptionContainerHeader}
                    color="primary"
                  >
                    <TranslatedText
                      id="main.ourCompany.header"
                      values={{
                        color: (text) => `<span class='${classes.secondaryColor}'>${text}</span>`,
                      }}
                    />
                  </Typography>
                </Box>
                <Box py={3}>
                  <Typography className={classes.descriptionContainerText}>
                    <TranslatedText
                      id="main.ourCompany.description.part1.before"
                    />
                      <Tooltip placement="top-start" title={ <TranslatedText id="main.ourCompany.description.tooltip" />}>
                      <span>[...]</span>
                      </Tooltip>
                    <TranslatedText
                      id="main.ourCompany.description.part1.after"
                    />
                  </Typography>




                  {!isCollapsed && (
                    <Typography className={classes.descriptionContainerText}>
                      <TranslatedText id="main.ourCompany.description.part2" />
                    </Typography>
                  )}
                  <Box
                    className={cn(classes.descriptionContainerSeeMoreLess)}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    pt={3}
                  >
                    <Box pr={1} clone>
                      <Typography color="secondary">
                        <TranslatedText
                          id={`career.ourRecruitmentTeam.${isCollapsed ? 'expandText' : 'collapseText'}`}
                        />
                      </Typography>
                    </Box>
                    {isCollapsed ? <ExpandMoreIcon color="secondary" /> : <ExpandLessIcon color="secondary" />}
                  </Box>
                </Box>
              </Box>
              <Box
                flex={1}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                className={classes.imageContainer}
              >
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <img src={officeImg.publicURL} alt="headquarter" />
                  <Button color="secondary" variant="contained" className={classes.button}>
                    <a target="_blank" rel="noreferrer" href="https://novomatic.com/">
                      <TranslatedText id="main.ourOffice.checkOutNovomatic" />
                    </a>
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </TrackVisibility>
      </SectionContainer>
    </Box>
  );
};

export default MainOurCompany;
