import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  makeStyles, Box,
} from '@material-ui/core';
import SectionContainer from '../shared/SectionContainer';
import TranslatedText from '../shared/TranslatedText';

const useStyles = makeStyles((theme) => ({
  background: {
    position: 'absolute',
    zIndex: -1,
    backgroundColor: theme.palette.background.lightBlue,
    width: '100%',
    height: 'calc(100% - 185px)',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  container: {
    maxWidth: 1200,
    padding: theme.spacing(4, 2),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  awardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(8),
  },
  awardLabel: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
}));

const AWARDS_CONFIG = [
  {
    label: 'Perła honorowa 2019',
    image: 'award1',
  },
  {
    label: 'Perła innowacji 2018',
    image: 'award2',
  },
  {
    label: 'Diamenty Forbes 2014',
    image: 'award3',
  },
];

const MainAwards = () => {
  const awardsImages = useStaticQuery(graphql`
    {
      award1: file(relativePath: {regex: "/main/awards/"}, name: {eq: "Award_1"}) {
        publicURL
      }
      award2: file(relativePath: {regex: "/main/awards/"}, name: {eq: "Award_2"}) {
        publicURL
      }
      award3: file(relativePath: {regex: "/main/awards/"}, name: {eq: "Award_3"}) {
        publicURL
      }
    }
  `);

  const classes = useStyles();
  return (
    <Box position="relative">
      <Box className={classes.background} />
      <SectionContainer
        header={<TranslatedText id="main.awards.header" />}
        subheader={<TranslatedText id="main.awards.subheader" />}
        upMdPaddingX={5}
      >
        <Box className={classes.container}>
          {AWARDS_CONFIG.map(({ label, image }) => (
            <Box key={image} className={classes.awardContainer}>
              <img height={260} src={awardsImages[image]?.publicURL} alt="award" />
              <Box fontSize={18} className={classes.awardLabel} color="primary">
                {label}
              </Box>
            </Box>
          ))}
        </Box>
      </SectionContainer>
    </Box>
  );
};

export default MainAwards;
