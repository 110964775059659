import * as React from 'react';
import Helmet from 'react-helmet';
import MainIntro from '../components/main/MainIntro';
import MainWhatWeDo from '../components/main/MainWhatWeDo';
import MainOurCompany from '../components/main/MainOurCompany';
import MainIntroAnimation from '../components/main/MainIntroAnimation';
import MainTechToolbox from '../components/main/MainTechToolbox';
import MainFindOutMore from '../components/main/MainFindOutMore';
import MainWhoWeAre from '../components/main/MainWhoWeAre';
import MainAwards from '../components/main/MainAwards';
import MainGoToCareers from '../components/main/MainGoToCareers';
import Layout from '../components/layout/layout';
import CareerOffers from '../components/career/CareerOffers';
import en from '../intl/en.json';
import pl from '../intl/pl.json';

const messages = { en, pl };

const IndexPage = ({ pageContext: { locale, offers, detailedOffers }, path }) => (
  <Layout locale={locale} path={path} hideContactDetails>
    <Helmet>
      <title>{`NOVOMATIC Poland - ${messages[locale]['menu.home.title']}`}</title>
      <meta name="description" content={messages[locale]['menu.home.description']} />
      <meta name="author" content="NOVOMATIC Technologies Poland Sp z o.o." />
      <link rel="icon" href={'/favicon.ico'} />
      <link rel="alternate" hrefLang="en" href="https://novomatic-tech.com/" />
      <link rel="alternate" hrefLang="pl" href="https://novomatic-tech.com/pl/" />
    </Helmet>
    <MainIntroAnimation />
    <MainIntro />
    <MainWhatWeDo />
    <MainOurCompany />
    <MainTechToolbox />
    <MainWhoWeAre />
    <MainAwards />
    <MainGoToCareers />
    <CareerOffers detailedOffers={detailedOffers} offers={offers || []} />
    <MainFindOutMore />
  </Layout>
);

export default IndexPage;
