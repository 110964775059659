import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { makeStyles, Box, Typography } from '@material-ui/core';
import SectionContainer from '../shared/SectionContainer';
import getItems, { ITEM_SIZE, ITEM_SIZE_SMALL } from './config/whatWeDo';
import TranslatedText from '../shared/TranslatedText';

const MAX_WIDTH = 1200;
const items = getItems();

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.lightBlue,
  },
  grid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, ${ITEM_SIZE}px)`,
    justifyContent: 'center',
    maxWidth: MAX_WIDTH,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: `repeat(auto-fill, ${ITEM_SIZE_SMALL}px)`,
      maxWidth: ITEM_SIZE_SMALL,
    },
  },
  gridItem: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 220,
    width: ITEM_SIZE,
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: ITEM_SIZE_SMALL,
    },
  },
  gridItemLabel: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 500,
  },
  gridItemDescription: {
    color: theme.palette.text.hint,
  },
}));

const MainWhatWeDo = () => {
  const classes = useStyles();

  const { allFile: { edges: images } } = useStaticQuery(graphql`
    {
      allFile(filter: {relativePath: {regex: "/main/whatWeDo/"}}) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `);

  return (
    <Box className={classes.container}>
      <SectionContainer
        header={<TranslatedText id="main.whatWeDo.header" />}
        subheader={<TranslatedText id="main.whatWeDo.subheader" />}
        upMdPaddingX={5}
      >
        <Box py={3} className={classes.grid}>
          {items.map(({ label, image, description }) => {
            const imageURL = images.find(({ node: { name } }) => name === image)?.node.publicURL;
            return (
              <Box key={`grid-item-${label.trim()}`} className={classes.gridItem}>
                <Box height={70}><img alt={label} src={imageURL} /></Box>
                <Box py={1}>
                  <Typography className={classes.gridItemLabel}><TranslatedText id={label} /></Typography>
                </Box>
                <Typography className={classes.gridItemDescription}>
                  <TranslatedText id={description} />
                </Typography>
              </Box>
            );
          })}
        </Box>
      </SectionContainer>
    </Box>
  );
};

export default MainWhatWeDo;
