import React from 'react';
import cn from 'classnames';
import TrackVisibility from 'react-on-screen';
import {
  Box, Typography, useMediaQuery, useTheme, makeStyles,
} from '@material-ui/core';
import TranslatedText from '../shared/TranslatedText';
import SectionContainer from '../shared/SectionContainer';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1200,
    padding: theme.spacing(13, 2),
  },
  slogan: {
    '& > h3': {
      lineHeight: '72px',
    },
  },
  description: {
    fontSize: theme.spacing(2.25),
    color: theme.palette.text.hint,
    lineHeight: '26px',
  },
  invisible: {
    visibility: 'hidden',
  },
  background:{
    backgroundColor: 'rgb(255,255,255)'
  }
}));

const MainIntro = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  return (
    <SectionContainer className={classes.background}>
      <TrackVisibility once throttleInterval={100} partialVisibility>
        {({ isVisible }) => (
          <Box
            className={cn(classes.container, classes.invisible, { 'ntp-block-animated': isVisible })}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            width="100%"
            boxSizing="border-box"
            maxWidth="1200"
            textAlign={isSmallScreen ? 'center' : 'left'}
          >
            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems={isSmallScreen ? 'center' : 'flex-start'}
              flexDirection="column"
              className={classes.slogan}
              pb={isSmallScreen ? 4 : 0}
            >
              <Typography variant="h3" color="primary">
                <TranslatedText id="main.intro.slogan.part1" />
              </Typography>
              <Typography variant="h3" color="primary">
                <TranslatedText id="main.intro.slogan.part2" />
              </Typography>
              <Typography variant="h3" color="secondary">
                <TranslatedText id="main.intro.slogan.part3" />
              </Typography>
            </Box>
            <Box flex={1} display="flex" alignItems="center" justifyContent="flex-start">
              <Typography className={classes.description}>
                <TranslatedText id="main.intro.description" />
              </Typography>
            </Box>
          </Box>
        )}
      </TrackVisibility>
    </SectionContainer>
  );
};

export default MainIntro;
