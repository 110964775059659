import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  makeStyles, Box, Typography, Tabs, Tab,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SectionContainer from '../shared/SectionContainer';
import TranslatedText from '../shared/TranslatedText';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 1200,
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {
    display: 'flex',
  },
  wrapper: {
    '& > span': {
      color: theme.palette.primary.main,
      textTransform: 'none',
    },
  },
  navItem: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    fontSize: 26,
    lineHeight: '51px',
    fontWeight: 300,
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      lineHeight: '36px',
    },
    '&$navItemSelected': {
      fontWeight: 600,
    },
  },
  navItemSelected: {},
  tabIndicator: {
    height: 4,
    borderRadius: 4,
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6, 0, 0, 0),
    boxSizing: 'border-box',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& img': {
        maxWidth: '100%',
        width: '100%',
      },
    },
  },
  sideImageContainer: {
    maxWidth: '100%',
    width: 400,
    '& > img': {
      width: '100%',
    },
  },
  contentDescription: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  contentDescriptionHeader: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(4),
    fontWeight: 600,
  },
  contentDescriptionContent: {
    color: theme.palette.text.hint,
  },
  head: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& img': {
      maxWidth: 230,
    },
  },
  headDescription: {
    color: theme.palette.text.hint,
    display: 'flex',
    flexDirection: 'column',
  },
  headContent: {
    flex: 1,
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingTop: theme.spacing(2),
    },
  },
  headName: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(4),
    fontWeight: 600,
  },
  dot: {
    color: theme.palette.secondary.main,
    fontSize: 12,
    paddingRight: theme.spacing(2),
  },
    headImg:{
      borderRadius: '10px'
    }
}));

const TABS = ['ourMission', 'management', 'history'];

const MainWhoWeAre = () => {
  const {
    ourMissionImg, DRHImg, DAFImg, historyImg,
  } = useStaticQuery(graphql`
    {
      ourMissionImg: file(relativePath: {regex: "/whoWeAre/"}, name: {eq: "ourMission"}) {
        publicURL
      }
      DAFImg: file(relativePath: {regex: "/whoWeAre/"}, name: {eq: "DAF"}) {
        publicURL
      }
      DRHImg: file(relativePath: {regex: "/whoWeAre/"}, name: {eq: "DRH"}) {
        publicURL
      }
      historyImg: file(relativePath: {regex: "/whoWeAre/"}, name: {eq: "history"}) {
        publicURL
      }
    }
  `);
  const classes = useStyles();
  const [selectedSection, setSelectedSection] = useState(0);

  return (
    <SectionContainer
      upMdPaddingX={5}
      header={<TranslatedText id="main.whoWeAre.header" />}
      subheader={<TranslatedText id="main.whoWeAre.subheader" />}
    >
      <Box className={classes.container}>
        <Tabs
          variant="fullWidth"
          value={selectedSection}
          onChange={(_, value) => setSelectedSection(value)}
          indicatorColor="primary"
          textColor="primary"
          centered
          TabIndicatorProps={{
            className: classes.tabIndicator,
          }}
        >
          {TABS.map((label, index) => (
            <Tab
                key={`tab${index}`}
              fullWidth
              disableRipple
              classes={{ root: classes.navItem, selected: classes.navItemSelected, wrapper: classes.wrapper }}
              label={<TranslatedText id={`main.whoWeAre.${label}`} />}
            />
          ))}
        </Tabs>
        <Box className={classes.contentContainer}>
          {selectedSection === 0 && (
            <Box className={classes.content} display="flex">
              <Box pb={6}>
                <img src={ourMissionImg.publicURL} alt="our mission" />
              </Box>
              <Box className={classes.contentDescription}>
                <Typography className={classes.contentDescriptionHeader}>
                  <TranslatedText id="main.whoWeAre.ourMission.descriptionHeader" />
                </Typography>
                <Box py={2}>
                  <Typography className={classes.contentDescriptionContent}>
                    <TranslatedText id="main.whoWeAre.ourMission.description.part1" />
                  </Typography>
                </Box>
                <Box py={2}>
                  <Typography className={classes.contentDescriptionContent}>
                    <TranslatedText id="main.whoWeAre.ourMission.description.part2" />
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {selectedSection === 1 && (
            <Box className={classes.content} display="flex" flexDirection="column">
              <Box className={classes.head} pb={4}>
                <Box>
                  <img className={classes.headImg} width={200} src={DAFImg.publicURL} alt="Adam Fabrycy" />
                </Box>
                <Box pl={8} flex={1} className={classes.headContent}>
                  <Typography className={classes.headName}>Adam Fabrycy</Typography>
                  <Box className={classes.headDescription}>
                    <Box pt={2} pb={1}>
                      <Typography>
                        <TranslatedText id="main.whoWeAre.management.DAF.description.part1" />
                      </Typography>
                    </Box>
                    <Box py={1}>
                      <Typography>
                        <TranslatedText id="main.whoWeAre.management.DAF.description.part4" />
                      </Typography>
                    </Box>
                    <Box py={1}>
                      <Typography>
                        <TranslatedText id="main.whoWeAre.management.DAF.description.part5" />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.head}>
                <Box>
                  <img className={classes.headImg} width={200} src={DRHImg.publicURL} alt="Rafał Hędrzak" />
                </Box>
                <Box pl={8} flex={1} className={classes.headContent}>
                  <Typography className={classes.headName}>Rafał Hędrzak</Typography>
                  <Box className={classes.headDescription}>
                    <Box pt={2} pb={1}>
                      <Typography>
                        <TranslatedText id="main.whoWeAre.management.DRH.description.part1" />
                      </Typography>
                    </Box>
                    <Box py={1}>
                      <Typography>
                        <TranslatedText id="main.whoWeAre.management.DRH.description.part2" />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {selectedSection === 2 && (
            <Box className={classes.content} display="flex">
              <Box pb={6} className={classes.sideImageContainer}>
                <img src={historyImg.publicURL} alt="our mission" />
              </Box>
              <Box className={classes.contentDescription}>
                <Box py={2}>
                  <Typography className={classes.contentDescriptionContent}>
                    <TranslatedText id="main.whoWeAre.history.description.part1" />
                  </Typography>
                </Box>
                <Box py={2}>
                  <Typography className={classes.contentDescriptionContent}>
                    <TranslatedText id="main.whoWeAre.history.description.part2" />
                  </Typography>
                </Box>
                <Box py={2}>
                  <Typography className={classes.contentDescriptionContent}>
                    <TranslatedText id="main.whoWeAre.history.description.part3" />
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default MainWhoWeAre;
