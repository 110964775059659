import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  makeStyles, Box, Typography, Button,
} from '@material-ui/core';
import TranslatedText from '../shared/TranslatedText';
import Link from '../shared/LocalizedLink';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 1200,
    boxSizing: 'border-box',
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  descriptionContainer: {
    padding: theme.spacing(7, 0),
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(7),
    },
  },
  descriptionText: {
    color: theme.palette.text.hint,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  descriptionHeader: {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '44px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  descriptionButton: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    '& > a': {
      textDecoration: 'none',
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(2),
    '& img': {
      paddingLeft: 0,
      width: '100%',
      maxWidth: 700,
    },
  },
  textColor: {
    color: theme.palette.secondary.main,
  },
}));

const MainGoToCareers = () => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(name: {eq: "career_hero"}) {
        publicURL
      }
    }
  `);

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box flex={2} className={classes.descriptionContainer}>
          <Box py={2} clone>
            <Typography className={classes.descriptionHeader} variant="h4" color="primary">
              <TranslatedText
                id="main.goToCareers.description.header"
                values={{
                  color: (text) => `<span class='${classes.textColor}'>${text}</span>`,
                }}
              />
            </Typography>
          </Box>
          <Box py={2} clone>
            <Typography className={classes.descriptionText}>
              <TranslatedText id="main.goToCareers.description.part1" />
            </Typography>
          </Box>
          <Box py={2} clone>
            <Typography className={classes.descriptionText}>
              <TranslatedText id="main.goToCareers.description.part2" />
            </Typography>
          </Box>
          <Box py={2} className={classes.descriptionButton}>
            <Link to="/careers">
              <Button variant="contained" color="secondary">
                <TranslatedText id="main.goToCareers.description.button" />
              </Button>
            </Link>
          </Box>
        </Box>
        <Box flex={3} className={classes.imageContainer}>
          <img src={image.publicURL} alt="careers" />
        </Box>
      </Box>
    </Box>
  );
};

export default MainGoToCareers;
