import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSpring, animated } from 'react-spring';
import {
  makeStyles, useMediaQuery, useTheme, Box,
} from '@material-ui/core';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import Particles from "./MainParticles";

const SMALL_SCREEN_HEIGHT = 'calc(100vh - 66px)';

const useStyles = makeStyles((theme) => ({
    mainIntro:{
        background: 'radial-gradient(circle, #0D244C 0%, #112D5F 49.38%, #091E43 99.91%)',
    },
  container: {
    height: 'calc(100vh - 120px)',
    minHeight: 650,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 120px)',
      minHeight: 600,
    },
    [theme.breakpoints.down('sm')]: {
      height: SMALL_SCREEN_HEIGHT,
      minHeight: 600,
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 365,
      maxHeight: 450,
    },
    ...(isMobile && {
      minHeight: 365,
      maxHeight: 450,
    }),
  },
  animation: {
    height: 120,
    overflow: 'hidden',
    display: 'flex',
  },
  animationDot: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'block',
    width: '15px',
    height: '15px',
    borderRadius: '100%',
    background: '#ffffff',
  },
  animationWheel: {
    position: 'relative',
    width: '120px',
    padding: '0 25px',
    transition: 'top 800ms',
  },
  animationItem: {
    width: '120px',
    height: '120px',
    fontSize: '120px',
    lineHeight: '120px',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 1s',
    '& img': {
      width: '80%',
      height: '80%',
    },
    '& svg': {
      width: '80%',
      height: '100px',
      color: '#fff',
    },
  },
  animationCode: {
    position: 'relative',
    display: 'flex',
    padding: '0 60px',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '144px',
    marginTop: -40,
    fontWeight: '600',
    lineHeight: '1',
    color: '#FF9700',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'block',
      width: '60px',
      height: '10px',
      background: '#ffffff',
    },
    '&::before': {
      left: '0',
    },
    '&::after': {
      right: '0',
    },
  },
  labelContainer: {
    [theme.breakpoints.down('md')]: {
      transform: 'scale(0.6)',
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.4)',
    },
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(0.3)',
    },
    ...(isMobile && {
      transform: 'scale(0.3)',
    }),
  },
  par: {
    color: '#fff',
    fontSize: '48px',
    textAlign: 'center',
    position: 'relative',
    fontWeight: 100,
    letterSpacing: 1,
    marginTop: 20,
  },
  Dices: {
    position: 'absolute',
  },
  Dice1: {
    top: '20%',
    left: '85%',
  },
  Dice2: {
    top: '78%',
    left: '20%',
    [theme.breakpoints.down('sm')]: {
      left: '55%',
    },
    [theme.breakpoints.down('xs')]: {
      left: '45%',
      top: '72%',
      width: '50%'
    },
    ...(isMobile && {
      left: '45%',
      top: '50%',
    }),
  },
  Dice3: {
    top: '20%',
    left: '5%',
    [theme.breakpoints.down('xs')]: {
      top: '9%',
      left:'2%'
    },
    ...(isMobile && {
      top: '-5%',
    }),
  },
  Dice4: {
    top: '30%',
    left: '-10%',
    [theme.breakpoints.down('xs')]: {
      left: '-8%',
      top: '63%',
      width:'70%'
    },
    ...(isMobile && {
      top: 0,
      left: '-20vw',
    }),
  },
  Dice5: {
    top: '-5%',
    left: '20%',
    [theme.breakpoints.down('xs')]: {
      top: -80,
    },
    ...(isMobile && {
      top: -80,
    }),
  },
  Dice6: {
    top: '80%',
    left: '60%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  Dice7: {
    top: '-5%',
    left: '60%',
    [theme.breakpoints.down('xs')]: {
      top: '13px',
      left:'66%'
    },
    ...(isMobile && {
      top: -50,
    }),
  },
  Dice8: {
    top: '70%',
    left: '88%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    ...(isMobile && {
      display: 'none',
    }),
  },
}));

const getRandomLetters = (idx) => {
  switch (idx) {
  case 0: return ['A', 'B', 'C', 'D', 'E', 'F'];
  case 1: return ['G', 'H', 'I', 'J', 'K', 'L'];
  case 2: return ['M', 'N', 'O', 'P', 'Q', 'R'];
  default: return ['S', 'T', 'U', 'W', 'Y', 'Z'];
  }
};

const LETTERS = [
  ['E', 'D', '<', 'L', 'A'],
  ['N', 'R', 'C', 'E', 'D'],
  ['J', 'E', 'O', 'A', 'A'],
  ['O', 'A', 'D', 'R', 'P'],
  ['Y', 'M', 'E', 'N', 'T'],
];
const LAST_WORD = LETTERS.reduce((word, letters) => `${word}${letters[0]}`, '');

const OneMoreThing = () => {
  const { allFile: { edges: dices } } = useStaticQuery(graphql`
    {
    allFile(filter: {relativePath: {regex: "/introAnimation/"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
  `);

  const classes = useStyles();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const wheel1 = useRef(null);
  const wheel2 = useRef(null);
  const wheel3 = useRef(null);
  const wheel4 = useRef(null);
  const wheel5 = useRef(null);
  const [clicks, setClicks] = useState(2);
  const isBiggerThanSmScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const wheels = [wheel1, wheel2, wheel3, wheel4, wheel5];

  const runWheel = useCallback(() => {
    let time = 0;
    setClicks(clicks === 0 ? 4 : clicks - 1);
    wheels.forEach((wheel) => {
      if (clicks === 4) {
        if(wheel.current){
            wheel.current.style.transition = 'none';
            wheel.current.style.top = '-4200px';
        }

      }
      setTimeout(() => {
        if(wheel.current){
            wheel.current.style.transition = 'top 800ms';
            wheel.current.style.top = `${-clicks * 840}px`;
        }

      }, time);
      time += 300;
    });
  }, [clicks, wheels]);
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 100, tension: 50, friction: 140 } }));

  const calc = (x, y) => [Math.sqrt(x * x + y * y), y - window.innerHeight];

  let transformScale = 1;
  if (isMediumScreen) { transformScale = 0.7; }
  if (isSmallScreen) { transformScale = 0.6; }

  const trans = [
    (x) => `scale(${transformScale}) rotate3d(1, 1, 1, ${x / 100}deg)`,
    (x) => `scale(${transformScale}) translate3d(${x / 200}px,${-x / 100}px,0) rotate3d(1, 1, 1, ${x / 100}deg)`,
    (x) => `scale(${transformScale}) translate3d(${-x / 70}px,${x / 140}px,0) rotate3d(1, 1, 1, ${x / 100}deg)`,
    (x) => `scale(${transformScale}) translate3d(${-x / 150}px,${-x / 150}px,0) rotate3d(1, 1, 1, ${x / 100}deg)`,
    (x) => `scale(${transformScale}) translate3d(${x / 60}px,${x / 80}px,0) rotate3d(1, 1, 1, ${x / 100}deg)`,
    (x) => `scale(${transformScale}) translate3d(${-x / 80}px,${-x / 60}px,0) rotate3d(1, 1, 1, ${x / 100}deg)`,
    (x) => `scale(${transformScale}) translate3d(${-x / 50}px,${-x / 35}px,0) rotate3d(1, 1, 1, ${x / 100}deg)`,
    (x) => `scale(${transformScale}) translate3d(${x / 50}px,${x / 35}px,0) rotate3d(1, 1, 1, ${x / 100}deg)`,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      runWheel();
    }, 4000);
    return () => clearInterval(interval);
  }, [runWheel]);

  return (
    <Box className={classes.mainIntro} overflow='hidden' position='relative'>

        {isBiggerThanSmScreen? <Particles/> : null}
      <div
        className={classes.container}
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <div className={classes.labelContainer}>
          <div className={cn(classes.animation, classes.animationCode)}>
            {wheels.map((wheel, i) => (
              <React.Fragment key={`item-${i}`}>
                <div
                  className={classes.animationWheel}
                  ref={wheel}
                  style={{ top: -2520 }}
                >
                  {LETTERS[i].map((letter, idx) => (
                    <React.Fragment key={`${i}-${letter}-${idx}`}>
                      <div className={classes.animationItem}>{letter}</div>
                      {getRandomLetters(idx).map((randomLetter) => (
                        <div key={`${i}-${letter}-${randomLetter}`} className={classes.animationItem}>
                          {randomLetter}
                        </div>
                      ))}
                    </React.Fragment>
                  ))}
                  <div className={classes.animationItem}>{LAST_WORD[i]}</div>
                </div>
                {i < 4 && <div className={classes.animationDot} />}
              </React.Fragment>
            ))}
          </div>
          <div>
            <p className={classes.par}>
              Join the game
            </p>
          </div>
        </div>
        {dices.map(({ node: dice }, i) => (
          <animated.img
            key={`dice-${i}`}
            className={cn(classes.Dices, classes[dice.name])}
            src={dice.publicURL}
            style={{ transform: props.xy.interpolate(trans[i]) }}
          />
        ))}
      </div>
    </Box>
  );
};

export default OneMoreThing;
